import { Box, Stack } from "@mui/material";
import { Box as PolarisBox } from "@shopify/polaris";
import styled from "styled-components";

export const EmailSettingsUpper = styled(Box)`
  & .Polaris-Icon--colorInkLighter::before {
    background-color: transparent !important;
  }

  & > .subheading {
    color: #6f7c88;
  }
  & .Polaris-Tabs__Wrapper {
    border-bottom: none;
  }

  & .Polaris-FormLayout--condensed {
    & > .Polaris-FormLayout__Items {
      & > .Polaris-FormLayout__Item {
        min-width: 27rem;
      }
    }

    & .Polaris-LegacyStack--vertical {
      margin-bottom: 2rem;
    }
  }
`;

export const EmailSettingsLower = styled(Box)`
  .Polaris-Box:not(:first-child) {
    margin-top: 16px;
  }

  & .email-config-row:not(:first-child) {
    border-top: var(--p-border-width-1) solid var(--p-color-border-subdued);
    padding-top: 20px;
  }
`;

export const NotificationBox = styled(PolarisBox)`
  > .Polaris-FormLayout {
    & .p-10 {
      padding: 1rem;
    }
    & textarea.Polaris-TextField__Input {
      overflow: auto;
      max-height: 300px;
    }
    > .Polaris-FormLayout__Item:nth-child(2) {
      margin-top: 1rem;
      > * > label {
        padding: 0;

        & + * {
          margin-top: 1rem;
        }
      }
    }
  }
  [class*="sizeSlim"] {
    margin-top: 20px !important;
    width: 134px !important;
  }
`;

//Fixed Sidecard
//TODO: Move a number of these components into /shared folder as these are used in places beyond customer notification
export const QuickJumpWrapper = styled(Box)`
  position: fixed;
  width: 100%;
  & .inner {
    padding: 1rem 20px 20px 20px;
  }
  & ul {
    & li {
      padding: 0.5rem 0rem;
      & a {
        text-decoration: underline;
      }
    }
  }
  .Polaris-LegacyCard {
    max-height: 500px;
    max-width: 272px;
    overflow-y: scroll;
  }

  & [class*="card-heading"] {
    background: #fff;
    height: 80px;
    position: fixed;
    width: 232px;
    margin-top: -20px;
    display: flex;
    align-items: center;

    & h2 {
      display: inline-flex;
      & .Polaris-Icon {
        margin-right: 1rem;
      }
    }
  }
  & .inner {
    margin-top: 40px;
  }
`;

export const TranslationsLinkContainer = styled.div`
  display: flex;
  margin-top: 10px;
  width: 272px;
  .Polaris-Icon {
    margin: 0 10px 0 0;
  }

  .Polaris-Text--root {
    max-width: 240px;
  }
`;

export const VariableCardsWrapper = styled.div`
  .Polaris-Card__Header {
    padding: 20px 20px 0px;
  }
  & .Polaris-Card__Header h2 {
    margin-bottom: 10px;
  }
  & .Polaris-DataTable__Navigation {
    display: none !important;
  }
  & .Polaris-DataTable__Cell--firstColumn {
    text-align: start;
  }

  & .table td:not([align]),
  table th:not([align]) {
    text-align: left;
  }
`;
export const CardHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 20px;

  & > .button-group {
    display: flex;
    justify-content: flex-end;

    button.Polaris-Button:not(.Polaris-Button--iconOnly) {
      margin-right: 10px;
    }
  }

  & > :first-child {
    align-items: center;
    display: flex;
    height: 34px;
    margin-bottom: 0;
    padding: 0;
  }
`;

export const CodeSnippet = styled.span`
  border: 1px solid #d2d5d8;
  border-radius: 4px;
  cursor: pointer;
  font-family: monospace;
  font-weight: 600;
  padding: 0rem 4px;
`;
export const CodeSnippetBlock = styled.p`
  border: 1px solid #d2d5d8;
  border-radius: 4px;
  font-family: monospace;
  font-weight: 600;
  padding: 0rem 4px !important;
`;

export const DividerLine = styled.hr`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const SubHeader = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const TightWrapper = styled.div`
  align-content: left;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
`;

export const StyledConfigRow = styled(Stack)<{ $toggled: boolean }>`
  & .email-config-row-title {
    width: 200px;
  }

  & .email-config-row-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 285px;

    & p {
      color: ${({ $toggled }) => ($toggled ? "inherit " : "#8C9196")};
    }

    & .email-config-row-conditional-badge {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;

      & .Polaris-Icon {
        max-width: 16px;
        margin: 0px;
        height: 16px;
      }
      & span {
        font-weight: 600;
      }
    }
  }
  & .email-config-row-switch {
    align-items: center;
    color: ${({ $toggled }) => ($toggled ? "#2877CC" : "var(--p-color-text-disabled)")};
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 84px;

    & span.Polaris-Text {
      width: 21px;
    }
  }
`;

export const EditEmailsPageWrapper = styled(Box)`
  .Polaris-Header-Title__TitleWithMetadataWrapper {
    flex-wrap: inherit;
    & h1 {
      width: max-content;
    }
  }
  .Polaris-Banner {
    margin-bottom: 20px;
    & .Polaris-Link {
      color: var(--p-color-text-interactive);
      display: contents;
      &:hover {
        color: var(--p-color-text-interactive-hover);
        text-decoration: none;
      }
    }
  }
  .Polaris-Page--divider {
    padding-top: 20px;
  }
`;

export const EmailPreviewWrapper = styled(Box)<{ modal?: boolean }>`
  ${props =>
    !props.modal &&
    `
    border-top: var(--p-border-width-1) solid var(--p-color-border-subdued);
    margin: 20px 0;
    padding: 20px 0;
`}

  // Resetting outsides styles affecting emails
  .box {
    background-color: transparent;
    border-radius: 0px;
    box-shadow: none;
    color: inherit;
    margin-bottom: 0;
    padding: 0;
  }

  // Ensuring email fills card completely
  table.mw-100p,
  table.templateContainer {
    width: inherit !important;

    .pb-5 {
      padding-bottom: 5px !important;
    }
  }
`;

export const EmailPreviewUpper = styled("div")`
  .Polaris-Box[id*="HelpText"] {
    padding-left: 0;
  }
`;
